import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';


function Shopshopall() {
   
  const [data, setData] = useState([])
   
  const getData = () => {
    fetch('Plantslist.json',{headers : {
        'Content-Type' : 'application/json',
        'Accept': 'application/json'
    }}).then((response)=>{
        return response.json()
    }).then((myjson) =>{
        // console.log(myjson)
        setData(myjson)
    })
  }
  
  useEffect(() => {
    getData()
  },[])
  
  
  
    return (
    <>
       {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

        <section id="subheader" className="relative bg-light">
            <div className="container relative z-index-1000">
                <div className="row">
                    <div className="col-lg-6">
                        <ul className="crumb">
                            <li><a href="shop-homepage.html">Home</a></li>
                            <li className="active">Shop All</li>
                        </ul>
                        <h1 className="text-uppercase">Shop All</h1>
                        <p className="col-lg-10 lead">Transform Your Garden into a Personal Paradise!</p>
                    </div>
                </div>
            </div>
            <img src="assets/images/logo-wm.webp" className="abs end-0 bottom-0 z-2 w-20" alt="error"/>
        </section>

        <section>
            <div className="container">
                <div className="row g-4">

                    <div className="col-lg-12">
                        <div className="row g-4">
   { 
                         data && data.length > 0 && data.map((val) => {
                          return(
                        <>
                            
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="de__pcard text-center">
                                    <div className="atr__images">
                                        <div className="atr__promo">
                                            {val.Discount}
                                        </div>
                                        <a href="shop-product-single.html">
                                            <img className="atr__image-main" src={val.Plantimageurl} alt="error"/>
                                            <img className="atr__image-hover" src="assets/images/shop/plants/peace-lily-m.webp" alt="error"/>
                                        </a>
                                        <div className="atr__extra-menu">
                                            <Link to='/single-product' className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></Link>
                                        </div>
                                    </div>

                                    <h3>{val.Plantname}</h3>

                                    <div className="atr__main-price">
                                        {val.Plantprice}
                                    </div>

                                    <div className="de-rating-ext">
                                        <span className="d-stars">{val.Plantrating}  
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <button className="btn-main bg-color-2">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                    })
                  }

                            <div className="col-lg-12 pt-4 text-center">
                                <div className="d-inline-block">
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination">
                                        <li className="page-item">
                                          <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true"><i className="fa fa-chevron-left"></i></span>
                                          </a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item active" aria-current="page"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                          <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true"><i className="fa fa-chevron-right"></i></span>
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
            

    </div>
    {/* <!-- content end --> */}
    </>
  )
}

export default Shopshopall;
