import React from 'react'

function Shopcart() {
  return (
    <>
      
      
      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section className="bg-light pt-20 pb-20 mt75 sm-mt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="crumb m-0">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Carts</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-100 pb-100">
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        <div className="col-md-6">
                            <div className="cart-items">                
                                {/* <!-- cart item begin --> */}
                                <div className="de__cart">
                                    <div className="de__cart-item justify-content-between">
                                        <div className="d-wrap">
                                            <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                                            <label for="item" className="d-checkbox__label align-items-center"></label>
                                            <img src="assets/images/shop/plants/peace-lily-l.webp" alt="error"/>
                                            <div className="d-info">
                                                <div>
                                                    <h4>Peace Lily</h4>                                
                                                    <span className="d-price">$150</span> 
                                                </div>         
                                            </div>  
                                        </div>

                                        <div className="de-number">
                                            <span className="d-minus">-</span>
                                            <input type="text" className="no-border no-bg" value="1"/>
                                            <span className="d-plus">+</span>
                                        </div>
                                    </div>
                                </div>   
                                {/* <!-- cart item end --> */}

                                {/* <!-- cart item begin --> */}
                                <div className="de__cart">
                                    <div className="de__cart-item justify-content-between">
                                        <div className="d-wrap">
                                            <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                                            <label for="item" className="d-checkbox__label align-items-center"></label>
                                            <img src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                                            <div className="d-info">
                                                <div>
                                                    <h4>Aglonema Plant</h4>                                
                                                    <span className="d-price">$150</span> 
                                                </div>         
                                            </div>  
                                        </div>

                                        <div className="de-number">
                                            <span className="d-minus">-</span>
                                            <input type="text" className="no-border no-bg" value="1"/>
                                            <span className="d-plus">+</span>
                                        </div>
                                    </div>
                                </div>   
                                {/* <!-- cart item end --> */}

                                {/* <!-- cart item begin --> */}
                                <div className="de__cart">
                                    <div className="de__cart-item justify-content-between">
                                        <div className="d-wrap">
                                            <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                                            <label for="item" className="d-checkbox__label align-items-center"></label>
                                            <img src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                            <div className="d-info">
                                                <div>
                                                    <h4>Jade Mini Plant</h4>                                
                                                    <span className="d-price">$150</span> 
                                                </div>         
                                            </div>  
                                        </div>

                                        <div className="de-number">
                                            <span className="d-minus">-</span>
                                            <input type="text" className="no-border no-bg" value="1"/>
                                            <span className="d-plus">+</span>
                                        </div>
                                    </div>
                                </div>   
                                {/* <!-- cart item end --> */}

                                {/* <!-- cart item begin --> */}
                                <div className="de__cart">
                                    <div className="de__cart-item justify-content-between">
                                        <div className="d-wrap">
                                            <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                                            <label for="item" className="d-checkbox__label align-items-center"></label>
                                            <img src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                            <div className="d-info">
                                                <div>
                                                    <h4>Monstera Deliciosa Plant</h4>                                
                                                    <span className="d-price">$150</span> 
                                                </div>         
                                            </div>  
                                        </div>

                                        <div className="de-number">
                                            <span className="d-minus">-</span>
                                            <input type="text" className="no-border no-bg" value="1"/>
                                            <span className="d-plus">+</span>
                                        </div>
                                    </div>
                                </div>   
                                {/* <!-- cart item end --> */}

                                {/* <!-- cart item begin --> */}
                                <div className="de__cart">
                                    <div className="de__cart-item justify-content-between">
                                        <div className="d-wrap">
                                            <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                                            <label for="item" className="d-checkbox__label align-items-center"></label>
                                            <img src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                            <div className="d-info">
                                                <div>
                                                    <h4>Anthurium Red Plant</h4>                                
                                                    <span className="d-price">$150</span> 
                                                </div>         
                                            </div>  
                                        </div>

                                        <div className="de-number">
                                            <span className="d-minus">-</span>
                                            <input type="text" className="no-border no-bg" value="1"/>
                                            <span className="d-plus">+</span>
                                        </div>
                                    </div>
                                </div>   
                                {/* <!-- cart item end --> */}

                                {/* <!-- cart item begin --> */}
                                <div className="de__cart">
                                    <div className="de__cart-item justify-content-between">
                                        <div className="d-wrap">
                                            <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                                            <label for="item" className="d-checkbox__label align-items-center"></label>
                                            <img src="assets/images/shop/plants/peace-lily-l.webp" alt="error"/>
                                            <div className="d-info">
                                                <div>
                                                    <h4>Peace Lily</h4>                                
                                                    <span className="d-price">$150</span> 
                                                </div>         
                                            </div>  
                                        </div>

                                        <div className="de-number">
                                            <span className="d-minus">-</span>
                                            <input type="text" className="no-border no-bg" value="1"/>
                                            <span className="d-plus">+</span>
                                        </div>
                                    </div>
                                </div>   
                                {/* <!-- cart item end -->                    */}
                            </div>                  
                        </div>
                    </div>
                </div>
            </section>

            
        </div>
        {/* <!-- content end --> */}


    </>
  )
}

export default Shopcart;
