import React from 'react'

function Contact() {
  return (
    <>
      
      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section id="subheader" className="relative bg-light">
                <div className="container relative z-index-1000">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="crumb">
                                <li><a href="shop-homepage.html">Home</a></li>
                                <li className="active">Contact</li>
                            </ul>
                            <h1 className="text-uppercase">Contact</h1>
                            <p className="col-lg-10 lead">Transform Your Garden into a Personal Paradise!</p>
                        </div>
                    </div>
                </div>
                <img src="assets/images/logo-wm.webp" className="abs end-0 bottom-0 z-2 w-20" alt="error"/>
            </section>

            <section>
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-6">
                            <h3 className="wow fadeInUp">Send Your Message</h3>

                            <p>Whether you have a question, a suggestion, or just want to say hello, this is the place to do it. Please fill out the form below with your details and message, and we'll get back to you as soon as possible.</p>

                            <div className="spacer-single"></div>

                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/latest-blog-img-1.webp)"></div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30">
                                            <div className="fw-bold text-dark"><i className="icofont-clock-time me-2 id-color-2"></i>We're Open</div>
                                            Monday - Sunday 08.00 A.M - 18.00 P.M

                                            <div className="spacer-20"></div>

                                            <div className="fw-bold text-dark"><i className="icofont-location-pin me-2 id-color-2"></i>Office Location</div>
                                            Near Ace Aspire,Amrapali Leisure Valley,Greator Noida,UttarPradesh-201306

                                            <div className="spacer-20"></div>

                                            <div className="fw-bold text-dark"><i className="icofont-phone me-2 id-color-2"></i>Call Us Directly</div>
                                            9220404309,9463563049,9911654089

                                            <div className="spacer-20"></div>

                                            <div className="fw-bold text-dark"><i className="icofont-envelope me-2 id-color-2"></i>Send a Message</div>
                                            Contact@meribagiya.com                                            
                                        </div>
                                    </div>                             
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-6">
                            <div className="p-4 rounded-10px">
                                <form name="contactForm" id="contact_form" className="position-relative z1000" method="post" action="#">
                                    
                                    <div className="field-set">
                                        <input type="text" name="Name" id="name" className="form-control" placeholder="Your Name" required/>
                                    </div>

                                    <div className="field-set">
                                        <input type="text" name="Email" id="email" className="form-control" placeholder="Your Email" required/>
                                    </div>

                                    <div className="field-set">
                                        <input type="text" name="phone" id="phone" className="form-control" placeholder="Your Phone" required/>
                                    </div>

                                    <div className="field-set mb20">
                                        <textarea name="message" id="message" className="form-control" placeholder="Your Message" required></textarea>
                                    </div>
                                        
                                    
                                    <div className="g-recaptcha" data-sitekey="6LdW03QgAAAAAJko8aINFd1eJUdHlpvT4vNKakj6"></div>
                                    <div id='submit' className="mt20">
                                        <input type='submit' id='send_message' value='Send Message' className="btn-main"/>
                                    </div>

                                    <div id="success_message" className='success'>
                                        Your message has been sent successfully. Refresh this page if you want to send more messages.
                                    </div>
                                    <div id="error_message" className='error'>
                                        Sorry there was an error sending your form.
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>            

    </div>
    {/* <!-- content end --> */}


    </>
  )
}

export default Contact
