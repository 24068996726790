import React from 'react'

function Services() {
  return (
    <>
      
     
      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section id="subheader" className="relative jarallax text-light">
                <img src="assets/images/background/7.webp" className="jarallax-img" alt="error"/>
                <div className="container relative z-index-1000">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="crumb">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">News</li>
                            </ul>
                            <h1 className="text-uppercase">Our Services</h1>
                            <p className="col-lg-10 lead">Transform Your Garden into a Personal Paradise!</p>
                        </div>
                    </div>
                </div>
                <img src="assets/images/logo-wm.webp" className="abs end-0 bottom-0 z-2 w-20" alt="error"/>
                <div className="de-gradient-edge-top dark"></div>
                <div className="de-overlay"></div>
            </section>

            <section className="p-4">
                <div className="container-fluid">
                    <div className="row g-4">
                        {/* <!-- service item begin --> */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="hover rounded-1 overflow-hidden relative text-light text-center wow fadeInRight" data-wow-delay=".0s">
                                <img src="assets/images/services/1.webp" className="hover-scale-1-1 w-100" alt="error"/>
                                <div className="abs w-100 px-4 hover-op-1 z-4 hover-mt-40 abs-centered">
                                    <div className="mb-3">Imagine stepping into your own private oasis—a garden designed just for you, where every plant, path, and stone tells your story.</div>
                                    <a className="btn-line" href="service-single.html">View Details</a>
                                </div>
                                <img src="assets/images/logo-icon.webp" className="abs abs-centered w-20" alt="error"/>
                                <div className="abs bg-color z-2 top-0 w-100 h-100 hover-op-1"></div>
                                <div className="abs z-2 bottom-0 mb-3 w-100 text-center hover-op-0">
                                    <h4 className="mb-3">Garden Design</h4>
                                </div>
                                <div className="gradient-trans-color-bottom abs w-100 h-40 bottom-0"></div>
                            </div>
                        </div>
                        {/* <!-- service item end --> */}

                        {/* <!-- service item begin --> */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="hover rounded-1 overflow-hidden relative text-light text-center wow fadeInRight" data-wow-delay=".3s">
                                <img src="assets/images/services/2.webp" className="hover-scale-1-1 w-100" alt="error"/>
                                <div className="abs w-100 px-4 hover-op-1 z-4 hover-mt-40 abs-centered">
                                    <div className="mb-3">A beautiful garden is more than just a space—it's a living, breathing part of your home. But maintaining that beauty takes time and expertise.</div>
                                    <a className="btn-line" href="service-single.html">View Details</a>
                                </div>
                                <img src="assets/images/logo-icon.webp" className="abs abs-centered w-20" alt="error"/>
                                <div className="abs bg-color z-2 top-0 w-100 h-100 hover-op-1"></div>
                                <div className="abs z-2 bottom-0 mb-3 w-100 text-center hover-op-0">
                                    <h4 className="mb-3">Garden Maintenance</h4>
                                </div>
                                <div className="gradient-trans-color-bottom abs w-100 h-40 bottom-0"></div>
                            </div>
                        </div>
                        {/* <!-- service item end --> */}

                        {/* <!-- service item begin --> */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="hover rounded-1 overflow-hidden relative text-light text-center wow fadeInRight" data-wow-delay=".6s">
                                <img src="assets/images/services/3.webp" className="hover-scale-1-1 w-100" alt="error"/>
                                <div className="abs w-100 px-4 hover-op-1 z-4 hover-mt-40 abs-centered">
                                    <div className="mb-3">Create an inviting space for entertaining, or a functional extension of your home, our expert team can craft the outdoor area of your dreams.</div>
                                    <a className="btn-line" href="service-single.html">View Details</a>
                                </div>
                                <img src="assets/images/logo-icon.webp" className="abs abs-centered w-20" alt="error"/>
                                <div className="abs bg-color z-2 top-0 w-100 h-100 hover-op-1"></div>
                                <div className="abs z-2 bottom-0 mb-3 w-100 text-center hover-op-0">
                                    <h4 className="mb-3">Decking and Patio</h4>
                                </div>
                                <div className="gradient-trans-color-bottom abs w-100 h-40 bottom-0"></div>
                            </div>
                        </div>
                        {/* <!-- service item end --> */}

                        {/* <!-- service item begin --> */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="hover rounded-1 overflow-hidden relative text-light text-center wow fadeInRight" data-wow-delay=".0s">
                                <img src="assets/images/services/4.webp" className="hover-scale-1-1 w-100" alt="error"/>
                                <div className="abs w-100 px-4 hover-op-1 z-4 hover-mt-40 abs-centered">
                                    <div className="mb-3">Create an inviting space for entertaining, or a functional extension of your home, our expert team can craft the outdoor area of your dreams.</div>
                                    <a className="btn-line" href="service-single.html">View Details</a>
                                </div>
                                <img src="assets/images/logo-icon.webp" className="abs abs-centered w-20" alt="error"/>
                                <div className="abs bg-color z-2 top-0 w-100 h-100 hover-op-1"></div>
                                <div className="abs z-2 bottom-0 mb-3 w-100 text-center hover-op-0">
                                    <h4 className="mb-3">Plant Selection</h4>
                                </div>
                                <div className="gradient-trans-color-bottom abs w-100 h-40 bottom-0"></div>
                            </div>
                        </div>
                        {/* <!-- service item end --> */}

                        {/* <!-- service item begin --> */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="hover rounded-1 overflow-hidden relative text-light text-center wow fadeInRight" data-wow-delay=".3s">
                                <img src="assets/images/services/5.webp" className="hover-scale-1-1 w-100" alt="error"/>
                                <div className="abs w-100 px-4 hover-op-1 z-4 hover-mt-40 abs-centered">
                                    <div className="mb-3">Create an inviting space for entertaining, or a functional extension of your home, our expert team can craft the outdoor area of your dreams.</div>
                                    <a className="btn-line" href="service-single.html">View Details</a>
                                </div>
                                <img src="assets/images/logo-icon.webp" className="abs abs-centered w-20" alt="error"/>
                                <div className="abs bg-color z-2 top-0 w-100 h-100 hover-op-1"></div>
                                <div className="abs z-2 bottom-0 mb-3 w-100 text-center hover-op-0">
                                    <h4 className="mb-3">Garden Irrigation</h4>
                                </div>
                                <div className="gradient-trans-color-bottom abs w-100 h-40 bottom-0"></div>
                            </div>
                        </div>
                        {/* <!-- service item end --> */}

                        {/* <!-- service item begin --> */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="hover rounded-1 overflow-hidden relative text-light text-center wow fadeInRight" data-wow-delay=".6s">
                                <img src="assets/images/services/6.webp" className="hover-scale-1-1 w-100" alt="error"/>
                                <div className="abs w-100 px-4 hover-op-1 z-4 hover-mt-40 abs-centered">
                                    <div className="mb-3">Create an inviting space for entertaining, or a functional extension of your home, our expert team can craft the outdoor area of your dreams.</div>
                                    <a className="btn-line" href="service-single.html">View Details</a>
                                </div>
                                <img src="assets/images/logo-icon.webp" className="abs abs-centered w-20" alt="error"/>
                                <div className="abs bg-color z-2 top-0 w-100 h-100 hover-op-1"></div>
                                <div className="abs z-2 bottom-0 mb-3 w-100 text-center hover-op-0">
                                    <h4 className="mb-3">Outdoor Lighting</h4>
                                </div>
                                <div className="gradient-trans-color-bottom abs w-100 h-40 bottom-0"></div>
                            </div>
                        </div>
                        {/* <!-- service item end --> */}

                    </div>
                </div>
            </section>

            <section className="jarallax pt80 pb80 relative text-light">
                <img src="assets/images/background/3.webp" className="jarallax-img" alt="error"/>
                <div className="container relative z-2">
                    <div className="row">
                        <div className="col-lg-8">
                            <img src="assets/images/logo-icon.webp" className="w-60px mb-4" alt="error"/>
                            <h2 className="text-uppercase mb-4">Transform Your Garden – Contact Us for a Free Consultation</h2>
                            <a className="btn-main" href="contact.html">Free Consultation</a>
                        </div>
                    </div>
                </div>
                <div className="de-gradient-edge-bottom dark"></div>
                <div className="de-overlay"></div>
            </section>
            
        </div>
        {/* <!-- content end --> */}


    </>
  )
}

export default Services;
