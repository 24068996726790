import React from 'react'

function Blog() {
  return (
    <>
      

      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section id="subheader" className="relative jarallax text-light">
                <img src="assets/images/background/8.webp" className="jarallax-img" alt="error"/>
                <div className="container relative z-index-1000">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="crumb">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Blog</li>
                            </ul>
                            <h1 className="text-uppercase">Blog</h1>
                            <p className="col-lg-10 lead">Transform Your Garden into a Personal Paradise!</p>
                        </div>
                    </div>
                </div>
                <img src="assets/images/logo-wm.webp" className="abs end-0 bottom-0 z-2 w-20" alt="error"/>
                <div className="de-gradient-edge-top dark"></div>
                <div className="de-overlay"></div>
            </section>

            <section>
                <div className="container">
                    <div className="row g-4">

                        <div className="col-lg-6">
                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/latest-blog-img-1.webp)">
                                            <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                                <div className="fs-36 mb-0">20</div>
                                                <span>Jun</span>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30 pb-60">
                                            <h4><a className="text-dark" href="blog-single.html">Creative Garden Layouts for Every Space</a></h4>
                                            <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>
                                        
                                            <div className="abs bottom-0 pb-20">
                                                <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                            </div>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/latest-blog-img-2.webp)">
                                            <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                                <div className="fs-36 mb-0">20</div>
                                                <span>Jun</span>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30 pb-60">
                                            <h4><a className="text-dark" href="blog-single.html">Top Trends in Modern Garden Landscaping</a></h4>
                                            <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>
                                        
                                            <div className="abs bottom-0 pb-20">
                                                <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                            </div>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/3.webp)">
                                            <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                                <div className="fs-36 mb-0">20</div>
                                                <span>Jun</span>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30 pb-60">
                                            <h4><a className="text-dark" href="blog-single.html">DIY Tips for a Stunning Garden Makeover</a></h4>
                                            <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>
                                        
                                            <div className="abs bottom-0 pb-20">
                                                <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                            </div>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-6">
                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/4.webp)">
                                            <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                                <div className="fs-36 mb-0">20</div>
                                                <span>Jun</span>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30 pb-60">
                                            <h4><a className="text-dark" href="blog-single.html">Low-Maintenance Garden Landscaping Ideas</a></h4>
                                            <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>
                                        
                                            <div className="abs bottom-0 pb-20">
                                                <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                            </div>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/5.webp)">
                                            <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                                <div className="fs-36 mb-0">20</div>
                                                <span>Jun</span>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30 pb-60">
                                            <h4><a className="text-dark" href="blog-single.html">Seasonal Plants for a Year-Round Garden</a></h4>
                                            <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>
                                        
                                            <div className="abs bottom-0 pb-20">
                                                <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                            </div>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div>                        
                        
                        <div className="col-lg-6">
                            <div className="rounded-1 bg-light overflow-hidden">
                                <div className="row g-2">
                                    <div className="col-sm-6">
                                        <div className="auto-height relative" data-bgimage="url(assets/images/blog/6.webp)">
                                            <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                                <div className="fs-36 mb-0">20</div>
                                                <span>Jun</span>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="col-sm-6 relative">
                                        <div className="p-30 pb-60">
                                            <h4><a className="text-dark" href="blog-single.html">Elegant Pathways to Enhance Your Garden</a></h4>
                                            <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>
                                        
                                            <div className="abs bottom-0 pb-20">
                                                <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                            </div>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div>
                        
                        {/* <!-- pagination begin --> */}
                        <div className="col-lg-12 pt-4 text-center">
                            <div className="d-inline-block">
                                <nav aria-label="Page navigation example">
                                  <ul className="pagination">
                                    <li className="page-item">
                                      <a className="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true"><i className="fa fa-chevron-left"></i></span>
                                      </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item active" aria-current="page"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                      <a className="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true"><i className="fa fa-chevron-right"></i></span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                            </div>
                        </div>
                        {/* <!-- pagination end --> */}
                    </div>
                </div>
            </section>
            
        </div>
        {/* <!-- content end --> */}


    </>
  )
}

export default Blog;
