import React from 'react'
import { Link } from 'react-router-dom';

function Header() {
  return (
    <>
       {/* <!-- header begin --> */}
       <header className="header-light transparent">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex sm-pt10">
                            <div className="de-flex-col">
                                {/* <!-- logo begin --> */}
                                <div id="logo">
                                    <Link to=''>
                                        <img className="logo-main"
                                        style={{height:150,width:150,borderRadius:100}} 
                                        src="assets/images/MERI-BAGIYA-LOGO-UPDATED.png" 
                                        alt="error" />
                                        <img className="logo-scroll" 
                                        src="assets/images/logo-black.webp" 
                                        alt="error" />
                                        <img className="logo-mobile" 
                                        src="assets/images/logo-mobile.webp" 
                                        alt="error" />
                                    </Link>
                                </div>
                                {/* <!-- logo end --> */}
                            </div>
                            <div className="de-flex-col header-col-mid">
                                {/* <!-- mainemenu begin --> */}
                                <ul id="mainmenu">
                                    <li>
                                        <Link to="" className="menu-item" 
                                        href="shop-homepage.html">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/catalog" className="menu-item" 
                                        href="shop-catalog.html">
                                            Catalog
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/shop-all" className="menu-item" 
                                        href="shop-shop-all.html">
                                            Shop All
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" className= "menu-item">
                                            Contact Us
                                        </Link>
                                    </li>

                                </ul>
                                {/* <!-- mainmenu end --> */}
                            </div>
                            <div className="de-flex-col">
                            <input type="text" 
                                name="Name" 
                                id="name" 
                                className="de-quick-search ms-3 w-100 rounded-20" 
                                placeholder="search..."/>

                                <a className="de-icon-counter" 
                                   href="#">
                                    <div 
                                    className="d-counter">0
                                    </div>
                                    <img 
                                    src="assets/images/ui/heart.svg" 
                                    className="" 
                                    alt="error"/>
                                </a>

                             <div id="btn-cart" 
                                className="de-icon-counter">
                                    <div className="d-counter">
                                        5
                                    </div>
                                    <img src="assets/images/ui/cart.svg" 
                                         className="" 
                                         alt="error"/>
                             </div>                                

                                <span id="menu-btn"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        {/* <!-- header end --> */}
    </>
  )
};

export default Header;
