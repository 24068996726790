import React from 'react'

function Shopproductsingle() {
  return (
    <>
      
      
      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section className="bg-light pt-20 pb-20 mt75 sm-mt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="crumb m-0">
                                <li><a href="index.html">Home</a></li>
                                <li><a href="shop-homepage.html">Shop</a></li>
                                <li className="active">Aglonema Plant</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-100 pb-100">
                <div className="container">
                    <div className="row gy-4 gx-5">
                        <div className="col-md-6">
                            <div id="sync1" className="owl-carousel owl-theme">
                                <div className="item"><img src="assets/images/shop/plant-single/22.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/7.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/8.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/2b.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/2c.webp" className="w-100" alt="error"/></div>
                            </div>

                            <div id="sync2" className="owl-carousel owl-theme">
                                <div className="item"><img src="assets/images/shop/plant-single/22.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/7.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/8.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/2b.webp" className="w-100" alt="error"/></div>
                                <div className="item"><img src="assets/images/shop/plant-single/2c.webp" className="w-100" alt="error"/></div>
                            </div>

                        </div>

                        <div className="col-md-6">
                            <div className="de-rating-ext mb-3">
                                <span className="d-stars">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </span> 4.8
                            </div>
                            <h2 className="fs-40 fs-48">Aglonema Plant</h2> 
                            <p className="col-lg-10">Adipisicing pariatur occaecat exercitation sunt exercitation consectetur in dolor adipisicing consequat sunt magna occaecat occaecat.</p>
                            <div className="d-flex mb-4 align-items-center">
                                <div>
                                    <h3 className="fs-24 mb-0 me-2 line-through op-5">$195</h3>
                                </div>
                                <div>
                                    <h3 className="fs-32 mb-0 me-2">$95</h3>
                                </div>
                                <div>
                                    <span className="fs-18 fw-600 px-3 rounded-20px bg-color-2 text-white">Sale</span>
                                </div>
                            </div>                            

                            <div className="group radio__button mb-4">
                                <h5 className="mb-3">Select Plant Size</h5>
                                 <input type="radio" name="size" id="size_1" value="small" checked />
                                 <label for="size_1">Small</label>
                                  
                                 <input type="radio" name="size" id="size_2" value="small" />
                                 <label for="size_2">Medium</label>
                                  
                                   <input type="radio" name="size" id="size_3" value="small" />
                                 <label for="size_3">Large</label>
                            </div>

                            <div className="group radio__button mb-4">
                                <h5 className="mb-3">Select Planter</h5>
                                 <input type="radio" name="planter" id="planter_1" value="small" checked />
                                 <label for="planter_1">Terracotta</label>
                                  
                                 <input type="radio" name="planter" id="planter_2" value="small" />
                                 <label for="planter_2">Ceramic</label>
                                  
                                 <input type="radio" name="planter" id="planter_3" value="small" />
                                 <label for="planter_3">Plastic</label>

                                 <input type="radio" name="planter" id="planter_4" value="small" />
                                 <label for="planter_4">Concrete</label>
                            </div>

                            <div className="group radio__button mb-4">
                                <h5 className="mb-3">Quantity</h5>
                                <div className="de-number">
                                    <span className="d-minus">-</span>
                                    <input type="text" className="no-border no-bg" value="1"/>
                                    <span className="d-plus">+</span>
                                </div>
                            </div>
                            
                            <a className="btn-main mt-4" href="#">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </section>

            
        </div>
        {/* <!-- content end --> */}

        {/* <!-- overlay content begin --> */}
    <div id="extra-wrap" class="de-light cart-wrap">
    <div id="btn-close" class="dark">
        <span></span>
        <span></span>
    </div>

    <div id="extra-content">
        <img src="assets/images/logo-black.webp" class="w-150px" alt="error"/>

        <div class="spacer-30-line"></div>

        <h5 class="mb-3">Your Cart</h5>
        
        <div class="cart-items">                
            {/* <!-- cart item begin --> */}
            <div class="de__cart">
                <div class="de__cart-item justify-content-between">
                    <div class="d-wrap">
                        <input type="checkbox" id="item" name="item" class="d-checkbox__input" checked />
                        <label for="item" class="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/peace-lily-l.webp" alt="error"/>
                        <div class="d-info">
                            <div>
                                <h4>Peace Lily</h4>                                
                                <span class="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div class="de-number">
                        <span class="d-minus">-</span>
                        <input type="text" class="no-border no-bg" value="1"/>
                        <span class="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div class="de__cart">
                <div class="de__cart-item justify-content-between">
                    <div class="d-wrap">
                        <input type="checkbox" id="item" name="item" class="d-checkbox__input" checked />
                        <label for="item" class="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                        <div class="d-info">
                            <div>
                                <h4>Aglonema Plant</h4>                                
                                <span class="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div class="de-number">
                        <span class="d-minus">-</span>
                        <input type="text" class="no-border no-bg" value="1"/>
                        <span class="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div class="de__cart">
                <div class="de__cart-item justify-content-between">
                    <div class="d-wrap">
                        <input type="checkbox" id="item" name="item" class="d-checkbox__input" checked />
                        <label for="item" class="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                        <div class="d-info">
                            <div>
                                <h4>Jade Mini Plant</h4>                                
                                <span class="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div class="de-number">
                        <span class="d-minus">-</span>
                        <input type="text" class="no-border no-bg" value="1"/>
                        <span class="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div class="de__cart">
                <div class="de__cart-item justify-content-between">
                    <div class="d-wrap">
                        <input type="checkbox" id="item" name="item" class="d-checkbox__input" checked />
                        <label for="item" class="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                        <div class="d-info">
                            <div>
                                <h4>Monstera Deliciosa Plant</h4>                                
                                <span class="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div class="de-number">
                        <span class="d-minus">-</span>
                        <input type="text" class="no-border no-bg" value="1"/>
                        <span class="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div class="de__cart">
                <div class="de__cart-item justify-content-between">
                    <div class="d-wrap">
                        <input type="checkbox" id="item" name="item" class="d-checkbox__input" checked />
                        <label for="item" class="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                        <div class="d-info">
                            <div>
                                <h4>Anthurium Red Plant</h4>                                
                                <span class="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div class="de-number">
                        <span class="d-minus">-</span>
                        <input type="text" class="no-border no-bg" value="1"/>
                        <span class="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div class="de__cart">
                <div class="de__cart-item justify-content-between">
                    <div class="d-wrap">
                        <input type="checkbox" id="item" name="item" class="d-checkbox__input" checked />
                        <label for="item" class="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/peace-lily-l.webp" alt="error"/>
                        <div class="d-info">
                            <div>
                                <h4>Peace Lily</h4>                                
                                <span class="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div class="de-number">
                        <span class="d-minus">-</span>
                        <input type="text" class="no-border no-bg" value="1"/>
                        <span class="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end -->                    */}
        </div>

    </div>
</div>
    {/* <!-- overlay content end --> */}

    </>
  )
}

export default Shopproductsingle;
