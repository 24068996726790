import React from 'react'

function About() {
  return (
    <>
      
      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section id="subheader" className="relative jarallax text-light">
                <img src="assets/images/background/8.webp" className="jarallax-img" alt="error"/>
                <div className="container relative z-index-1000">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="crumb">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">About Us</li>
                            </ul>
                            <h1 className="text-uppercase">About Us</h1>
                            <p className="col-lg-10 lead">Transform Your Garden into a Personal Paradise!</p>
                        </div>
                    </div>
                </div>
                <img src="assets/images/logo-wm.webp" className="abs end-0 bottom-0 z-2 w-20" alt="error"/>
                <div className="de-gradient-edge-top dark"></div>
                <div className="de-overlay"></div>
            </section>

            <section>
                <div className="container relative z-1">
                    <div className="row g-4 gx-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="subtitle wow fadeInUp mb-3">Our Story</div>
                            <h2 className="text-uppercase wow fadeInUp" data-wow-delay=".2s">Crafting <span className="id-color-2">Beautiful Gardens</span> Since '99</h2>
                            <p className="wow fadeInUp">Established in 1999, our garden service has been transforming outdoor spaces into thriving, beautiful landscapes for over two decades. With a commitment to quality and personalized care, our experienced team offers a full range of services, from design to maintenance, ensuring your garden flourishes in every season.</p>
                            <a className="btn-main btn-line wow fadeInUp" href="projects.html" data-wow-delay=".6s">Our Projects</a>
                        </div>

                        <div className="col-lg-6">
                            <div className="row g-4">
                                <div className="col-sm-6">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <img src="assets/images/misc/3.webp" className="w-100 rounded-1 wow zoomIn" alt="error"/>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="rounded-1 relative bg-color-2 text-light p-4">
                                                <img src="assets/images/icons/tree.png" className="abs abs-middle w-60px" alt="error"/>
                                                <div className="de_count ps-80 wow fadeInUp">
                                                    <h2 className="mb-0 fs-32"><span className="timer" data-to="550" data-speed="3000"></span>+</h2>
                                                    <span className="op-7">Garden Designed</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="rounded-1 relative bg-color-2 text-light p-4">
                                                <img src="assets/images/icons/happy.png" className="abs abs-middle w-60px" alt="error"/>
                                                <div className="de_count ps-80 wow fadeInUp">
                                                    <h2 className="mb-0 fs-32"><span className="timer" data-to="850" data-speed="3000"></span>+</h2>
                                                    <span className="op-7">Happy Customers</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <img src="assets/images/misc/4.webp" className="w-100 rounded-1 wow zoomIn" alt="error"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </section>

            <section className="bg-light">
                <div className="container">
                    <div className="row g-4 mb-3 justify-content-center">
                        <div className="col-lg-6 text-center">
                            <div className="subtitle wow fadeInUp mb-3">Behind The Scene</div>
                            <h2 className="text-uppercase wow fadeInUp" data-wow-delay=".2s">Our <span className="id-color-2">Team</span></h2>
                        </div>
                    </div>

                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="relative wow fadeIn">
                                <div className="owl-custom-nav menu-float" data-target="#project-single-carousel">
                                    <a className="btn-next"></a>
                                    <a className="btn-prev"></a>                                

                                    <div id="project-single-carousel" className="owl-3-cols owl-carousel owl-theme">
                                        
                                        {/* <!-- team begin --> */}
                                        <div className="item">
                                            <div className="relative">
                                                <div className="abs bottom-0 w-100">
                                                    <div className="d-flex justify-content-between align-items-center rounded-1 m-4 bg-white p-4">
                                                        <div>
                                                            <h4 className="mb-0">William Carter</h4>
                                                            <p className="mb-0">Landscape Architect</p>                                        
                                                        </div>
                                                        <div className="text-end">
                                                            <a href="#"><i className="fa-brands fa-facebook-f fs-24 id-color bg-light w-40px h-40px pt-2 circle text-center"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/team/1.webp" className="w-100 rounded-10px" alt="error"/>
                                            </div>
                                        </div>
                                        {/* <!-- team end --> */}

                                        {/* <!-- team begin --> */}
                                        <div className="item">
                                            <div className="relative">
                                                <div className="abs bottom-0 w-100">
                                                    <div className="d-flex justify-content-between align-items-center rounded-1 m-4 bg-white p-4">
                                                        <div>
                                                            <h4 className="mb-0">Emily Grace</h4>
                                                            <p className="mb-0">Landscape Designer</p>                                        
                                                        </div>
                                                        <div className="text-end">
                                                            <a href="#"><i className="fa-brands fa-facebook-f fs-24 id-color bg-light w-40px h-40px pt-2 circle text-center"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/team/2.webp" className="w-100 rounded-10px" alt="error"/>
                                            </div>
                                        </div>
                                        {/* <!-- team end --> */}

                                        {/* <!-- team begin --> */}
                                        <div className="item">
                                            <div className="relative">
                                                <div className="abs bottom-0 w-100">
                                                    <div className="d-flex justify-content-between align-items-center rounded-1 m-4 bg-white p-4">
                                                        <div>
                                                            <h4 className="mb-0">Anthony Mitchell</h4>
                                                            <p className="mb-0">Garden Designer</p>                                        
                                                        </div>
                                                        <div className="text-end">
                                                            <a href="#"><i className="fa-brands fa-facebook-f fs-24 id-color bg-light w-40px h-40px pt-2 circle text-center"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/team/3.webp" className="w-100 rounded-10px" alt="error"/>
                                            </div>
                                        </div>
                                        {/* <!-- team end --> */}

                                        {/* <!-- team begin --> */}
                                        <div className="item">
                                            <div className="relative">
                                                <div className="abs bottom-0 w-100">
                                                    <div className="d-flex justify-content-between align-items-center rounded-1 m-4 bg-white p-4">
                                                        <div>
                                                            <h4 className="mb-0">Elizabeth Miller</h4>
                                                            <p className="mb-0">Irrigation Specialist</p>                                        
                                                        </div>
                                                        <div className="text-end">
                                                            <a href="#"><i className="fa-brands fa-facebook-f fs-24 id-color bg-light w-40px h-40px pt-2 circle text-center"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/team/4.webp" className="w-100 rounded-10px" alt="error"/>
                                            </div>
                                        </div>
                                        {/* <!-- team end --> */}

                                        {/* <!-- team begin --> */}
                                        <div className="item">
                                            <div className="relative">
                                                <div className="abs bottom-0 w-100">
                                                    <div className="d-flex justify-content-between align-items-center rounded-1 m-4 bg-white p-4">
                                                        <div>
                                                            <h4 className="mb-0">David Thompson</h4>
                                                            <p className="mb-0">Garden Technician</p>                                        
                                                        </div>
                                                        <div className="text-end">
                                                            <a href="#"><i className="fa-brands fa-facebook-f fs-24 id-color bg-light w-40px h-40px pt-2 circle text-center"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/team/5.webp" className="w-100 rounded-10px" alt="error"/>
                                            </div>
                                        </div>
                                        {/* <!-- team end --> */}

                                        {/* <!-- team begin --> */}
                                        <div className="item">
                                            <div className="relative">
                                                <div className="abs bottom-0 w-100">
                                                    <div className="d-flex justify-content-between align-items-center rounded-1 m-4 bg-white p-4">
                                                        <div>
                                                            <h4 className="mb-0">Anne Robinson</h4>
                                                            <p className="mb-0">Garden Consultant</p>                                        
                                                        </div>
                                                        <div className="text-end">
                                                            <a href="#"><i className="fa-brands fa-facebook-f fs-24 id-color bg-light w-40px h-40px pt-2 circle text-center"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/team/6.webp" className="w-100 rounded-10px" alt="error"/>
                                            </div>
                                        </div>
                                        {/* <!-- team end --> */}
                                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row g-4 mb-3 align-items-center justify-content-center">
                        <div className="col-lg-6 text-center">
                            <div className="subtitle wow fadeInUp">Why Choose Us</div>
                            <h2 className="text-uppercase wow fadeInUp" data-wow-delay=".2s">Our Commitment to <span className="id-color-2">Excellence</span></h2>
                        </div>                        
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                            <div className="relative h-100 bg-color text-light padding30 rounded-1">
                                <img src="assets/images/logo-icon.webp" className="w-50px mb-3" alt="error"/>
                                <div className="abs m-3 top-0 end-0 p-2 rounded-2 mb-3">01</div>
                                <div>
                                    <h4>Expertise and Experience</h4>
                                    <p className="mb-0">With years of hands-on experience, our team of professional gardeners and landscapers bring a wealth of knowledge to every project.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                            <div className="relative h-100 bg-color text-light padding30 rounded-1">
                                <img src="assets/images/logo-icon.webp" className="w-50px mb-3" alt="error"/>
                                <div className="abs m-3 top-0 end-0 p-2 rounded-2 mb-3">02</div>
                                <div>
                                    <h4>Personalized Service</h4>
                                    <p className="mb-0">We believe that every garden is unique, just like its owner. We take the time to understand your vision, preferences, and the specific needs.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                            <div className="relative h-100 bg-color text-light padding30 rounded-1">
                                <img src="assets/images/logo-icon.webp" className="w-50px mb-3" alt="error"/>
                                <div className="abs m-3 top-0 end-0 p-2 rounded-2 mb-3">03</div>
                                <div>
                                    <h4>Comprehensive Solutions</h4>
                                    <p className="mb-0">From garden design and installation to regular maintenance and specialty services, we offer a full range of garden services.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                            <div className="relative h-100 bg-color-2 text-light padding30 rounded-1">
                                <img src="assets/images/logo-icon.webp" className="w-50px mb-3" alt="error"/>
                                <div className="abs m-3 top-0 end-0 p-2 rounded-2 mb-3">04</div>
                                <div>
                                    <h4>Quality Workmanship</h4>
                                    <p className="mb-0">Our commitment to quality is evident in every service we provide. We use only the best materials, plants, and tools to your garden.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                            <div className="relative h-100 bg-color-2 text-light padding30 rounded-1">
                                <img src="assets/images/logo-icon.webp" className="w-50px mb-3" alt="error"/>
                                <div className="abs m-3 top-0 end-0 p-2 rounded-2 mb-3">05</div>
                                <div>
                                    <h4>Eco-Friendly Practices</h4>
                                    <p className="mb-0">We are dedicated to environmentally sustainable practices. Our organic gardening methods, water-wise landscaping, and  waste management.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                            <div className="relative h-100 bg-color-2 text-light padding30 rounded-1">
                                <img src="assets/images/logo-icon.webp" className="w-50px mb-3" alt="error"/>
                                <div className="abs m-3 top-0 end-0 p-2 rounded-2 mb-3">06</div>
                                <div>
                                    <h4>Satisfaction Guarantee</h4>
                                    <p className="mb-0">Our top priority is your satisfaction. We take pride in our work, and our many happy customers are a testament to the quality and care.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
       {/* <!-- content end --> */}

    </>
  )
}

export default About;
