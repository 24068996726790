import React from 'react';
import { Link} from 'react-router-dom';

function Shopcatalog() {
  return (
    <>
      
      {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

        <section id="subheader" className="relative bg-light">
            <div className="container relative z-index-1000">
                <div className="row">
                    <div className="col-lg-6">
                        <ul className="crumb">
                            <li><a href="shop-homepage.html">Home</a></li>
                            <li className="active">Catalog</li>
                        </ul>
                        <h1 className="text-uppercase">Catalog</h1>
                        <p className="col-lg-10 lead">Transform Your Garden into a Personal Paradise!</p>
                    </div>
                </div>
            </div>
            <img src="assets/images/logo-wm.webp" className="abs end-0 bottom-0 z-2 w-20" alt="error"/>
        </section>

        <section>
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#DCE0D9">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/5.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Natural Plants</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#E9E8E1">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/6.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Shop Gifts</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#dce0ce">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/8.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Garden Care</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#f3edde">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/9.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Garden Accesories</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        

        <section className="jarallax">
            <img src="assets/images/shop/slider/bg.webp" className="jarallax-img" alt="error"/>
            <div className="container">
                <div className="row g-4 align-items-center justify-content-between">

                    <div className="col-lg-5">     
                        <div className="sw-text-wrapper">
                            <div className="subtitle">Best Quality Plants</div>
                            <h2 className="mb-2 text-uppercase">Discover Amazing <span className="id-color-2">variety of plants</span></h2>
                            <p>From vibrant flowering plants to lush tropical greens, we offer an incredible variety to turn your space into a living paradise.</p>

                            <Link to="/shop-all" className="btn-main mb10 mb-3" href="shop-shop-all.html">Shop Now</Link>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="relative">
                            <img src="assets/images/shop/misc/7.webp" className="w-100" alt="error"/>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>

    </div>
    {/* <!-- content end --> */}

    </>
  )
};

export default Shopcatalog;
