import React from 'react';
import { Link } from 'react-router-dom';

function Shophomepage() {
  return (
    <>
    
        {/* <!-- content begin --> */}
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>

            <section id="section-intro" className="slider-light no-top no-bottom relative overflow-hidden z-1000">
                <div className="v-center relative">

                    <div className="swiper">
                      {/* <!-- Additional required wrapper --> */}
                      <div className="swiper-wrapper">

                        {/* <!-- Slides --> */}
                        <div className="swiper-slide">
                            <div className="swiper-inner jarallax">
                                <img src="assets/images/shop/slider/bg.webp" className="jarallax-img" alt="error"/>
                                <div className="sw-caption z-1000">
                                    <div className="container">
                                        <div className="row g-4 align-items-center justify-content-between">

                                            <div className="spacer-double"></div>

                                            <div className="col-lg-5">     
                                                <div className="spacer-single"></div>
                                                <div className="sw-text-wrapper">
                                                    <div className="subtitle">Best Quality Plants</div>
                                                    <h2 className="slider-title text-uppercase mb-4">Discover Amazing <span className="id-color-2">variety of plants</span></h2>
                                                    <p className="slider-text">From vibrant flowering plants to lush tropical greens, we offer an incredible variety to turn your space into a living paradise.</p>

                                                    <div className="d-flex mb-4 slider-extra xs-hide">
                                                        <div className="d-inline me-3 w-130px text-center overlay-white-6 p-3 rounded-1">
                                                            <img src="assets/images/shop/svg/coupon-svgrepo-com.svg" className="w-40 mb-1" alt="error"/>
                                                            <h6 className="mb-0">Special Price</h6>
                                                        </div>

                                                        <div className="d-inline me-3 w-130px text-center overlay-white-6 p-3 rounded-1">
                                                            <img src="assets/images/shop/svg/shipped-truck-svgrepo-com.svg" className="w-40 mb-1" alt="error"/>
                                                            <h6 className="mb-0">Free Delivery</h6>
                                                        </div>                                                        

                                                        <div className="d-inline me-3 w-130px text-center overlay-white-6 p-3 rounded-1">
                                                            <img src="assets/images/shop/svg/recommended-like-svgrepo-com.svg" className="w-40 mb-1" alt="error"/>
                                                            <h6 className="mb-0">Guarantee</h6>
                                                        </div>
                                                    </div>

                                                    <Link to="/shop-all" className="btn-main mb10 mb-3" href="shop-shop-all.html">Shop Now</Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="relative">
                                                    <div className="abs abs-middle bg-blur overlay-white-70 w-250px p-4 rounded-1">
                                                        <h5>Algonema Plant with Teracota Pot</h5>
                                                        <div className="de-rating-ext">
                                                            <span className="d-stars">
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <img src="assets/images/shop/slider/1.webp" className="w-100" alt="error"/>
                                                </div>
                                            </div>

                                            <div className="spacer-single"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>                        
                        {/* <!-- Slides --> */}

                        {/* <!-- Slides --> */}
                        <div className="swiper-slide">
                            <div className="swiper-inner jarallax">
                                <img src="assets/images/shop/slider/bg.webp" className="jarallax-img" alt="error"/>
                                <div className="sw-caption z-1000">
                                    <div className="container">
                                        <div className="row g-4 align-items-center justify-content-between">

                                            <div className="spacer-double"></div>

                                            <div className="col-lg-5">     
                                                <div className="spacer-single"></div>
                                                <div className="sw-text-wrapper">
                                                    <div className="subtitle">Best Quality Plants</div>
                                                    <h2 className="slider-title text-uppercase mb-4">Discover Amazing <span className="id-color-2">variety of plants</span></h2>

                                                    <div className="d-flex mb-4 slider-extra xs-hide">
                                                        <div className="d-inline me-3 w-130px text-center overlay-white-6 p-3 rounded-1">
                                                            <img src="assets/images/shop/svg/coupon-svgrepo-com.svg" className="w-40 mb-1" alt="error"/>
                                                            <h6 className="mb-0">Special Price</h6>
                                                        </div>

                                                        <div className="d-inline me-3 w-130px text-center overlay-white-6 p-3 rounded-1">
                                                            <img src="assets/images/shop/svg/shipped-truck-svgrepo-com.svg" className="w-40 mb-1" alt="error"/>
                                                            <h6 className="mb-0">Free Delivery</h6>
                                                        </div>                                                        

                                                        <div className="d-inline me-3 w-130px text-center overlay-white-6 p-3 rounded-1">
                                                            <img src="assets/images/shop/svg/recommended-like-svgrepo-com.svg" className="w-40 mb-1" alt="error"/>
                                                            <h6 className="mb-0">Guarantee</h6>
                                                        </div>
                                                    </div>

                                                    <p className="slider-text">From vibrant flowering plants to lush tropical greens, we offer an incredible variety to turn your space into a living paradise.</p>
                                                    <a className="btn-main mb10 mb-3" href="shop-shop-all.html">Shop Now</a>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="relative">
                                                    <div className="abs abs-middle bg-blur overlay-white-70 w-250px p-4 rounded-1">
                                                        <h5>Algonema Plant with Teracota Pot</h5>
                                                        <div className="de-rating-ext">
                                                            <span className="d-stars">
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <img src="assets/images/shop/slider/2.webp" className="w-100" alt="error"/>
                                                </div>
                                            </div>

                                            <div className="spacer-single"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>                        
                        {/* <!-- Slides -->                       */}
                    </div>

                    {/* <!-- If we need navigation buttons --> */}
                    <div className="swiper-button-prev no-bg text-dark"></div>
                    <div className="swiper-button-next no-bg text-dark"></div>

                </div>
            </div>
        </section>

        <section className="pb-0">
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#DCE0D9">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/5.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Natural Plants</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#E9E8E1">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/6.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Shop Gifts</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#dce0ce">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/8.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Garden Care</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="p-4 h-100 hover" data-bgcolor="#f3edde">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <img src="assets/images/shop/misc/9.webp" className="w-100 hover-scale-1-1" alt="error"/>
                                </div>
                                <div className="col-md-6">
                                    <span>Up to 50% Off</span>
                                    <h3>Garden Accesories</h3>
                                    <Link to="/shop-all" className="btn-main bg-color-2" href="shop-shop-all.html">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        
        <section>
            <div className="container">
                <div className="row g-4 mb-4">
                    <div className="col-lg-12">
                        <div className="owl-custom-nav menu-" data-target="#new-arrivals-carousel">
                            <div className="d-flex justify-content-between">
                                <h3 className="text-uppercase mb-4">New Arrivals</h3>
                                <div className="arrow-simple">
                                    <a className="btn-prev"></a> 
                                    <a className="btn-next"></a>
                                </div>
                            </div>


                            <div id="new-arrivals-carousel" className="owl-carousel owl-4-cols">
                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/peace-lily-s.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/peace-lily-m.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Peace Lily Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>

                                        {/* <div className="atr__opt">
                                            <div className="atr__opt-1 active" data-image="assets/images/shop/plants/peace-lily-s.webp">S</div>
                                            <div className="atr__opt-2" data-image="assets/images/shop/plants/peace-lily-m.webp">M</div>
                                            <div className="atr__opt-3" data-image="assets/images/shop/plants/peace-lily-l.webp">L</div>
                                        </div> */}
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/plastic-pot-1.webp" alt="error"/>
                                                <img className="atr__image-hover full" src="assets/images/shop/plants/plastic-pot-hover.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Plastic Pot</h3>
                                        <div className="atr__main-price">
                                            $20.00
                                        </div>

                                        {/* <div className="atr__colors">
                                            <div className="atr__color-1 active" data-image="assets/images/shop/plants/plastic-pot-1.webp"><span data-bgcolor="#C4DBCC"></span></div>
                                            <div className="atr__color-2" data-image="assets/images/shop/plants/plastic-pot-2.webp"><span data-bgcolor="#BDC788"></span></div>
                                            <div className="atr__color-3" data-image="assets/images/shop/plants/plastic-pot-3.webp"><span data-bgcolor="#c29dc0"></span></div>
                                            <div className="atr__color-3" data-image="assets/images/shop/plants/plastic-pot-4.webp"><span data-bgcolor="#e0e0e0"></span></div>
                                            <div className="atr__color-3" data-image="assets/images/shop/plants/plastic-pot-5.webp"><span data-bgcolor="#505050"></span></div>
                                        </div> */}
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Aglonema Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Jade Plant Mini</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Monstera Deliciosa Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Anthurium Red Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/snake-plant.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/snake-plant.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Snake Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>

        <section className="bg-light">
            <div className="container">
                <div className="row g-4 mb-4">
                    <div className="col-lg-12">
                        <div className="owl-custom-nav menu-" data-target="#today-deals-carousel">
                            <div className="d-flex justify-content-between">
                                <h3 className="text-uppercase mb-4">Today Deals</h3>
                                <div className="arrow-simple">
                                    <a className="btn-prev"></a> 
                                    <a className="btn-next"></a>
                                </div>
                            </div>


                            <div id="today-deals-carousel" className="owl-carousel owl-4-cols">

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/calathea-sanderiana.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/calathea-sanderiana.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Calathea Sanderiana</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/zz-plant.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/zz-plant.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>ZZ Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/lemon-grass.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/lemon-grass.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Lemon Grass Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/ficus-lyrata.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/ficus-lyrata.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Ficus Lyrata Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/aloe-vera-mini.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/aloe-vera-mini.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Aloe Vera Mini Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/peperomia.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/peperomia.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Peperomia Green Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>            

        <section className="jarallax">
            <img src="assets/images/background/7.webp" className="jarallax-img" alt="error"/>
            <div className="container relative z-2">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="owl-carousel owl-theme wow fadeInUp" id="testimonial-carousel">
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets\images\testimonial\1.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Rakesh</div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"Meri Bagiya has an amazing collection of plants! From flowering plants to rare indoor species, they have it all. The plants are healthy and well-maintained. I’m so happy with the vibrant flowering plants I purchased—they’ve truly transformed my garden. Highly recommend this place for all gardening enthusiasts!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets\images\testimonial\2.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Ram</div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"The staff at Meri Bagiya is super knowledgeable and friendly. They helped me pick the perfect plants for my balcony garden and even gave me tips on how to care for them. The prices are also very reasonable compared to other nurseries. A delightful experience overall!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets\images\testimonial\3.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Ankush</div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"As a beginner gardener, I was nervous about what to buy and how to care for plants. The team at Meri Bagiya patiently guided me and suggested low-maintenance plants. Their detailed care instructions were super helpful. Now, I feel confident and enjoy gardening every day. Thank you!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets\images\testimonial\4.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Shubham</div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"The nursery itself is so peaceful and beautifully organized—it’s like stepping into a green paradise. They also offer home delivery, which was so convenient for me. My plants arrived in perfect condition. I’ll definitely keep coming back for more!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets/images/testimonial/5.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Carlos R.<span>Customer</span></div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"Absolutely fantastic service! My phone's screen was cracked, and GadgetFix had it fixed in no time. The repair was flawless, and the customer service was top-notch. Highly recommend!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets/images/testimonial/6.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Edward B.<span>Customer</span></div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"Absolutely fantastic service! My phone's screen was cracked, and GadgetFix had it fixed in no time. The repair was flawless, and the customer service was top-notch. Highly recommend!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets/images/testimonial/7.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Daniel H.<span>Customer</span></div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"Absolutely fantastic service! My phone's screen was cracked, and GadgetFix had it fixed in no time. The repair was flawless, and the customer service was top-notch. Highly recommend!"</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg-light relative p-4">
                                    <i className="icofont-quote-right abs top-0 end-0 fs-32 m-4 id-color-2"></i>
                                    <div className="relative">
                                        <img className="relative z-2 w-60px circle" alt="error" src="assets/images/testimonial/8.webp"/>
                                    </div>
                                    <div className="mt-3 fw-600">Bryan G.<span>Customer</span></div>
                                    <div className="de-rating-ext mb-3">
                                        <span className="d-stars">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </span>
                                    </div>
                                    <p>"Absolutely fantastic service! My phone's screen was cracked, and GadgetFix had it fixed in no time. The repair was flawless, and the customer service was top-notch. Highly recommend!"</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <div className="row g-4 mb-4">
                    <div className="col-lg-12">
                        <div className="owl-custom-nav menu-" data-target="#best-seller-carousel">
                            <div className="d-flex justify-content-between">
                                <h3 className="text-uppercase mb-4">Best Sellers</h3>
                                <div className="arrow-simple">
                                    <a className="btn-prev"></a> 
                                    <a className="btn-next"></a>
                                </div>
                            </div>


                            <div id="best-seller-carousel" className="owl-carousel owl-4-cols">
                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/peace-lily-s.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/peace-lily-s.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Peace Lily Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Aglonema Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Jade Plant Mini</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Monstera Deliciosa Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end -->                                     */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Anthurium Red Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/snake-plant.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/snake-plant.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Snake Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/peace-lily-s.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/peace-lily-s.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Peace Lily Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Aglonema Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Jade Plant Mini</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Monstera Deliciosa Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end -->                                     */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Anthurium Red Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                                {/* <!-- product item begin --> */}
                                <div className="item">
                                    <div className="de__pcard text-center">
                                        <div className="atr__images">
                                            <div className="atr__promo">
                                                Sale
                                            </div>
                                            <a href="shop-product-single.html">
                                                <img className="atr__image-main" src="assets/images/shop/plants/snake-plant.webp" alt="error"/>
                                                <img className="atr__image-hover" src="assets/images/shop/plants/snake-plant.webp" alt="error"/>
                                            </a>
                                            <div className="atr__extra-menu">
                                                <a className="atr__quick-view" href="shop-product-single.html"><i className="icon_zoom-in_alt"></i></a>
                                                {/* <div className="atr__add-cart"><i className="icon_cart_alt"></i></div>
                                                <div className="atr__wish-list"><i className="icon_heart_alt"></i></div> */}
                                            </div>
                                        </div>

                                        <div className="de-rating-ext">
                                            <span className="d-stars">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                        </div>

                                        <h3>Snake Plant</h3>
                                        <div className="atr__main-price">
                                            $195.00
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <!-- product item end --> */}

                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>

        <section className="jarallax">
            <img src="assets/images/shop/slider/bg.webp" className="jarallax-img" alt="error"/>
            <div className="container">
                <div className="row g-4 align-items-center justify-content-between">

                    <div className="col-lg-5">     
                        <div className="sw-text-wrapper">
                            <div className="subtitle">Best Quality Plants</div>
                            <h2 className="mb-2 text-uppercase">Discover Amazing <span className="id-color-2">variety of plants</span></h2>
                            <p>From vibrant flowering plants to lush tropical greens, we offer an incredible variety to turn your space into a living paradise.</p>

                            <Link to="/shop-all" className="btn-main mb10 mb-3" href="shop-shop-all.html">Shop Now</Link>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="relative">
                            <img src="assets/images/shop/misc/7.webp" className="w-100" alt="error"/>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>

        <section>
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-12 text-center">
                        <h3 className="text-uppercase">Latest Blog</h3>
                    </div>
                    <div className="col-lg-6">
                        <div className="bg-light overflow-hidden">
                            <div className="row g-2">
                                <div className="col-sm-6">
                                    <div className="auto-height relative" data-bgimage="url(assets/images/blog/latest-blog-img-1.webp)">
                                        <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                            <div className="fs-36 mb-0">20</div>
                                            <span>Jun</span>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-sm-6 relative">
                                    <div className="p-30 pb-60">
                                        <h4><a className="text-dark" href="blog-single.html">Creative Garden Layouts for Every Space</a></h4>
                                        <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>

                                        <div className="abs bottom-0 pb-20">
                                            <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                        </div>
                                    </div>
                                </div>                             
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="bg-light overflow-hidden">
                            <div className="row g-2">
                                <div className="col-sm-6">
                                    <div className="auto-height relative" data-bgimage="url(assets/images/blog/latest-blog-img-2.webp)">
                                        <div className="abs start-0 top-0 bg-color-2 text-white p-3 pb-2 m-3 text-center fw-600 rounded-5px">
                                            <div className="fs-36 mb-0">20</div>
                                            <span>Jun</span>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-sm-6 relative">
                                    <div className="p-30 pb-60">
                                        <h4><a className="text-dark" href="blog-single.html">Top Trends in Modern Garden Landscaping</a></h4>
                                        <p className="mb-0">This month has been bustling with exciting developments in the world of plants. From groundbreaking new devices to innovative...</p>

                                        <div className="abs bottom-0 pb-20">
                                            <div className="d-inline fs-14 fw-600 me-3"><i className="icofont-chat id-color-2 me-2"></i>10 Comments</div>
                                        </div>
                                    </div>
                                </div>                             
                            </div>
                        </div>
                    </div> 

                </div>
            </div>
        </section>

    </div>
    {/* <!-- content end --> */}


    {/* <!-- overlay content begin --> */}
<div id="extra-wrap" className="de-light cart-wrap">
    <div id="btn-close" className="dark">
        <span></span>
        <span></span>
    </div>

    <div id="extra-content">
        <img src="assets/images/logo-black.webp" className="w-150px" alt="error"/>

        <div className="spacer-30-line"></div>

        <h5 className="mb-3">Your Cart</h5>
        
        <div className="cart-items">                
            {/* <!-- cart item begin --> */}
            <div className="de__cart">
                <div className="de__cart-item justify-content-between">
                    <div className="d-wrap">
                        <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                        <label for="item" className="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/peace-lily-l.webp" alt="error"/>
                        <div className="d-info">
                            <div>
                                <h4>Peace Lily</h4>                                
                                <span className="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div className="de-number">
                        <span className="d-minus">-</span>
                        <input type="text" className="no-border no-bg" value="1"/>
                        <span className="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div className="de__cart">
                <div className="de__cart-item justify-content-between">
                    <div className="d-wrap">
                        <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                        <label for="item" className="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/aglonema.webp" alt="error"/>
                        <div className="d-info">
                            <div>
                                <h4>Aglonema Plant</h4>                                
                                <span className="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div className="de-number">
                        <span className="d-minus">-</span>
                        <input type="text" className="no-border no-bg" value="1"/>
                        <span className="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div className="de__cart">
                <div className="de__cart-item justify-content-between">
                    <div className="d-wrap">
                        <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                        <label for="item" className="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/jade-mini.webp" alt="error"/>
                        <div className="d-info">
                            <div>
                                <h4>Jade Mini Plant</h4>                                
                                <span className="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div className="de-number">
                        <span className="d-minus">-</span>
                        <input type="text" className="no-border no-bg" value="1"/>
                        <span className="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div className="de__cart">
                <div className="de__cart-item justify-content-between">
                    <div className="d-wrap">
                        <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                        <label for="item" className="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/monstera-deliciosa.webp" alt="error"/>
                        <div className="d-info">
                            <div>
                                <h4>Monstera Deliciosa Plant</h4>                                
                                <span className="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div className="de-number">
                        <span className="d-minus">-</span>
                        <input type="text" className="no-border no-bg" value="1"/>
                        <span className="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div className="de__cart">
                <div className="de__cart-item justify-content-between">
                    <div className="d-wrap">
                        <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                        <label for="item" className="d-checkbox__label align-items-center"></label>
                        <img src="assets/images/shop/plants/anthurium-red.webp" alt="error"/>
                        <div className="d-info">
                            <div>
                                <h4>Anthurium Red Plant</h4>                                
                                <span className="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div className="de-number">
                        <span className="d-minus">-</span>
                        <input type="text" className="no-border no-bg" value="1"/>
                        <span className="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end --> */}

            {/* <!-- cart item begin --> */}
            <div className="de__cart">
                <div className="de__cart-item justify-content-between">
                    <div className="d-wrap">
                        <input type="checkbox" id="item" name="item" className="d-checkbox__input" checked />
                        <label for="item" className="d-checkbox__label align-items-center"></label>
                        <img src="images/shop/plants/peace-lily-l.webp" alt="error"/>
                        <div className="d-info">
                            <div>
                                <h4>Peace Lily</h4>                                
                                <span className="d-price">$150</span> 
                            </div>         
                        </div>  
                    </div>

                    <div className="de-number">
                        <span className="d-minus">-</span>
                        <input type="text" className="no-border no-bg" value="1"/>
                        <span className="d-plus">+</span>
                    </div>
                </div>
            </div>   
            {/* <!-- cart item end -->                    */}
        </div>

    </div>
</div>
{/* <!-- overlay content end --> */}

    </>
  )
};

export default Shophomepage;
