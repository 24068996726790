import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider,createRoutesFromElements,Route} from 'react-router-dom';
import Shophomepage from './pages/shop-homepage';
import Contact from './pages/shop-contact';
import Shopcatalog from './pages/shop-catalog';
import Shopshopall from './pages/shop-shop-all';
import Shopproductsingle from './pages/shop-product-single';
import Servicesingle from './pages/service-single';
import Blog from './pages/blog';
import About from './pages/about';
import Services from './pages/services';
import Shopcart from './pages/shop-cart';

// const router = createBrowserRouter([
//     {
//       path: '/',
//       element: <App/>,
//       children: [
//          {
//             path:"",
//             element: <Shophomepage/>
//          },
//          {
//             path:"contact",
//             element: <Contact/>
//          },
//          {
//             path:"catalog",
//             element: <Shopcatalog/>
//          },
//          {
//             path:"shop-all",
//             element: <Shopshopall/>
//          },
//          {
//             path:"single-product",
//             element: <Shopproductsingle/>
//          }
//       ]
//     }
// ])

const router = createBrowserRouter(
   createRoutesFromElements(
      <Route path="/" element={<App/>}>
         <Route path="" element={<Shophomepage/>}/>
         <Route path="catalog" element={<Shopcatalog/>}/>
         <Route path="shop-all" element={<Shopshopall/>}/>
         <Route path="single-product" element={<Shopproductsingle/>}/>
         <Route path="contact" element={<Contact/>}/>
         <Route path="service-single" element={<Servicesingle/>}/>
         <Route path="blog" element={<Blog/>}/>
         <Route path="about" element={<About/>}/>
         <Route path="services" element={<Services/>}/>
         <Route path="shop-cart" element={<Shopcart/>}/>
      </Route>
   )

)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
    <RouterProvider router={router}/>
   </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

